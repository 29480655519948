import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Home from '../components/Home';
import Content from '../components/Content';

function Index({ data }) {
  const page = data.site.edges[0].node.homePage;
  const {
    title,
    metaDescription,
    metaImage: { sizes: { src: metaImage } = {} } = {},
    content: { json } = {}
  } = page;
  const {
    fluid: { src } = {},
    description
  } = data.site.edges[0].node.homePageImage;
  const image = {
    url: src,
    alt: description
  };
  const {
    fluid: { src: mobileUrl } = {},
    description: mobileDescription
  } = data.site.edges[0].node.homePageImageMobile;
  const mobileImage = {
    url: mobileUrl,
    alt: mobileDescription
  };

  return (
    <>
      <SEO
        title={title}
        description={metaDescription && metaDescription.metaDescription}
        image={metaImage}
      />

      <Home
        content={<Content json={json} />}
        image={image}
        mobileImage={mobileImage}
      />
    </>
  );
}

export default Index;

export const query = graphql`
  query HomeQuery {
    site: allContentfulSite(limit: 1) {
      edges {
        node {
          homePage {
            title
            metaDescription {
              metaDescription
            }
            metaImage {
              sizes {
                src
              }
            }
            content {
              json
            }
          }
          homePageImage {
            fluid(maxWidth: 992, quality: 80) {
              src
            }
          }
          homePageImageMobile {
            fluid(maxWidth: 992, quality: 80) {
              src
            }
          }
        }
      }
    }
  }
`;
